<template>
	<div>
		<h3>{{ $t('monte.add_jument') }}</h3>
		<div>
            <form @submit.prevent>
	            <div class="form-group">
	                <div class="row">
	                	<div class="col-12">
			            	<div class="d-flex">
								<b-form-radio v-model="showTier" :value="false" class="mr-2">{{ $t('monte.contact') }}</b-form-radio>
								<b-form-radio v-model="showTier" :value="true" class="mr-2 mb-2">{{ $t('monte.tiers') }}</b-form-radio>
			            	</div>
			            </div>
	                	<div class="col">
	                		<SearchTiers
			            		v-if="showTier"
								:tiers_selected.sync="tiers"
								:search_value.sync="tiers_search_value"
								:preselected="tiers"
							/>
							<SearchContact
			            		v-else
								:contact_selected.sync="contact"
								:search_value.sync="contact_search_value"
								:preselected="contact"
							/>
		               	</div>
		               	<div class="col-auto">
		                	<button class="btn btn-secondary" @click="addTierOrContact"><font-awesome-icon :icon="['far', 'plus']" /></button>
		                </div>
		            </div>
	            </div>
	            <div class="form-group">
	                <label>{{ $t('monte.jument') }}</label>
	                <div class="row">
	                	<div class="col">
			                <SearchJument
								:horse_selected.sync="mare"
								:preselected="mare"
							/>
			            </div>
			            <div class="col-auto">
		                	<button class="btn btn-secondary" @click="addMare"><font-awesome-icon :icon="['far', 'plus']" /></button>
		                </div>
		            </div>
	            </div>
	            <div v-if="mare && mare.horse_id" class="form-group">
	            	<label>{{ $t('mouvement.lieu_stationnement') }}</label>
					<SearchTiers 
						:tiers_selected.sync="lieu_stationnement"
						:preselected="lieu_stationnement"
					/>
	            </div>
	            <div v-if="!no_couple && no_contract" class="alert alert-warning">{{ $t('monte.no_contract') }}</div>
	            <div v-if="no_couple" class="alert alert-warning">{{ $t('monte.no_couple') }}</div>
	            <div v-if="close_saillie" class="alert alert-warning">{{ close_saille_message }}</div>
	            <div v-if="stallion_preselected" class="alert alert-danger">{{ $t('monte.defaut_stallion_is') }} {{ default_stallion.horse_nom }}</div>
	            <div v-if="stallion_selected" class="alert alert-warning">{{ $t('monte.stallion_selected') }}</div>
	            <div v-if="mare.horse_id" class="form-group">
	                <label>{{ $t('gynecologie.mare_status') }}</label>
	                <div class="row">
	                	<div class="col">
			                <e-select
	                            v-model="mare_status_selected"
	                            id="marestatus_id"
	                            track-by="marestatus_id"
	                            :placeholder="$t('gynecologie.selectionnez_etat')"
	                            :selectedLabel="$t('global.selected_label')"
	                            :options="mare_status"
	                            :searchable="false"
	                            :allow-empty="false"
	                            :show-labels="false"
	                        >
	                            <template slot="option" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
	                            <template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
	                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	                        </e-select>
	                    </div>
		            </div>
	            </div>
	            <div v-if="mare.horse_id" class="form-group">
	            	<div class="row">
	                	<div class="col">
	            			<b-form-checkbox id="seasonmare_sanitary" v-model="sanitary"><label for="seasonmare_sanitary">{{ $t("gynecologie.sanitaire_complet") }}</label></b-form-checkbox>
	            		</div>
	            	</div>
	            </div>
	            <div class="form-group">
	            	<label>{{ $t('monte.stallion') }}</label>
	                <e-select
	                    v-model="stallion"
	                    id="stallion"
	                    track-by="horse_id"
	                    label="horse_nom"
	                    :placeholder="$t('monte.selectionnez_etalon')"
	                    :selectedLabel="$t('global.selected_label')"
	                    :options="stallion_dispo"
	                    :searchable="true"
	                    :allow-empty="false"
	                    :show-labels="false"
	                    :class="{ 'is-invalid': error && error.indexOf('stallion') > -1 }"
	                />
	            </div>
	            <div class="form-group">
	            	<label>{{ $t('monte.date') }}</label>
	                <e-datepicker v-model="date"></e-datepicker>
	            </div>
	            <div class="form-group">
	            	<label>{{ $t('monte.creneau') }}</label>
	                <e-select
	                    v-model="schedule"
	                    id="schedule"
	                    track-by="schedule_id"
	                    :placeholder="$t('monte.selectionnez_schedule')"
	                    :selectedLabel="$t('global.selected_label')"
	                    :options="schedule_dispo"
	                    :searchable="true"
	                    :allow-empty="false"
	                    :show-labels="false"
	                    :class="{ 'is-invalid': error && error.indexOf('schedule') > -1 }"
	                >
		                <template slot="option" slot-scope="{ option }">{{ option.schedule_start }} - {{ option.schedule_end }}</template>
	                	<template slot="singleLabel" slot-scope="{ option }">{{ option.schedule_start }} - {{ option.schedule_end }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
	               	</e-select>
	            </div>

	            <div class="form-group">
                    <label>{{ $t('formulaire.ajouter_commentaire') }}</label>
                    <textarea class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="commentaire"></textarea>
                </div>

	            <div class="text-center mt-5">
	                <b-button @click="checkForm" variant="primary" class="rounded-pill">
	                    <template v-if="schedule_info">
	                    	{{ $t('global.modifier') }} <font-awesome-icon v-if='processing' :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['fal', 'edit']" class="ml-1"/>
	                	</template>
	                	<template v-else>
	                    	{{ $t('global.ajouter') }} <font-awesome-icon v-if='processing' :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['fal', 'plus-circle']" class="ml-1"/>
	                	</template>
	                </b-button>
	                <b-button @click="cancelAjout" class="rounded-pill ml-1">
						{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
					</b-button>
	            </div>
	        </form>
        </div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import Horse from '@/mixins/Horse'
	import PlanningMonte from '@/mixins/PlanningMonte'
	import Contact from '@/mixins/Contact'
	import Gynecologie from '@/mixins/Gynecologie'
	import Contract from '@/mixins/Contract'
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'AjoutMonteIABeta',
		props: ['horse_id', 'schedule_info', 'planning_id', 'schedule_id', 'date_preselected', 'season_id'],
		mixins: [Shutter, Contract, MonteShutters, Horse, PlanningMonte, Contact, Gynecologie],
		data () {
			return {
				mare: {},
				stallion: null,
				stallion_dispo: [],
				contact: null,
				schedule: null,
				schedule_dispo: [],
				date: null,
				mare_status_selected: null,
				mare_status: [],
				season_mare: null,
				sanitary: false,
				loadingContact: false,
				loadingMare: false,
				commentaire: "",
				no_contract: false,
				no_couple: false,
				showTier: false,
				tiers: null,
				close_saillie: false,
				close_saille_message: '',
				lieu_stationnement: null,
				stallion_preselected: false,
				stallion_selected: false,
				tiers_search_value: '',
				contact_search_value: '',
				error: [],
				processing: false,
				default_stallion: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.mare_status = await this.loadMareStatus()

				if(!this.schedule_info) {
					this.loadStallion()
					if(this.date_preselected) {
						this.date = this.date_preselected
					}
				}
				else {
					this.mare = this.schedule_info.mare ? this.schedule_info.mare : {}
					this.date = new Date(this.schedule_info.date)
					this.contact = this.schedule_info.contact
					this.loadStallion(this.schedule_info.stallion.horse_id)
					this.commentaire = this.schedule_info.commentaire
					this.tiers = this.schedule_info.tiers
					if(this.tiers) {
						this.showTier = true
					}
				}

				this.all_schedule = await this.getSchedules(this.planning_id)
			},

			async addMare() {
				let shutter = this.monteShutters['monte-horse-ajout']
				shutter.props.season_id = this.season_id

				shutter.onOk = async (horse_id) => {
					if(this.contact) {
						await this.setContactHorse(horse_id)
					}

					this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season_id)
					this.season_mare = this.season_mare[0]
					this.mare = await this.getHorseById(horse_id)
				}

				this.shutterPanel().open(shutter)	
			}, 

			addTierOrContact() {
				if(this.showTier) {
					this.openAddTier()
				}
				else {
					this.addContact()
				}
			},

			addContact() {
				let shutter = this.monteShutters['monte-contact-ajout']

				shutter.props.horse_id = this.mare.horse_id

				this.shutterPanel().open(shutter)	
			},

			openAddTier() {
				let shutter = this.monteShutters['ajout-tiers']

				this.shutterPanel().open(shutter)
			},

			async setContactHorse(horse_id) {
				if(this.contact.contact_id) {
					await this.addHorseContact(horse_id, this.contact.contact_id)
				}
			},

			async loadStallion() {
				this.stallion_dispo = await this.getHorsesStallion(false)
				const stallion_planning = await this.getStallionPlanning(this.planning_id)
				this.stallion_dispo = this.stallion_dispo.filter(stallion => stallion_planning.includes(stallion.horse_id))
				this.stallion = this.stallion_dispo.find(stallion => stallion.horse_id == this.horse_id)
			},

			async loadSchedules() {
				let schedule_dispo = await this.getSchedulesByStallionIA(this.stallion.horse_id, this.planning_id)
				schedule_dispo = schedule_dispo.map(schedule => schedule.planninghorse_schedule)
				this.schedule_dispo = this.all_schedule.filter(schedule => schedule_dispo.includes(schedule.schedule_id))

				if(this.schedule_id) {
					this.schedule = this.schedule_dispo.find(schedule => schedule.schedule_id == this.schedule_id)
				}
			},

			async selectStatus() {
				this.stallion_preselected = false
				this.stallion_selected = false
				if(!this.mare.horse_id) return
					
				this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(this.mare.horse_id, this.season_id)
				this.season_mare = this.season_mare[0]
				if(this.season_mare) {
					this.mare_status_selected = this.mare_status.find(stat => stat.marestatus_id == this.season_mare.seasonmare_status)
					this.sanitary = this.season_mare.seasonmare_sanitary

					const seasonmarestallion = await this.getSeasonMareStallionDefaultBySeasonMare(this.season_mare.seasonmare_id)
					if(seasonmarestallion) {
						const new_stallion = this.stallion_dispo.find(stallion => stallion.horse_id == seasonmarestallion.seasonmarestallion_horse)

						if(new_stallion && this.stallion == null) {
							this.stallion = new_stallion
							this.stallion_selected = true
						}
						if(new_stallion && new_stallion.horse_id != this.stallion.horse_id) {
							this.default_stallion = new_stallion
							this.stallion_preselected = true
						}
					}
				} else {
                    await this.addHorseSeasonMare(this.season_id, this.mare.horse_id, '')
                    this.selectStatus()
				}

				const previous_season = await this.getPreviousSeasonFromSeason(this.season_id)
				if(previous_season) {
					const previous = await this.loadSeasonMareStallionBySeasonHorse(this.mare.horse_id, previous_season.season_id)
					if(previous.length > 0 && previous[0].seasonmare_status) {
						this.mare_status_selected = this.mare_status.find(stat => stat.marestatus_id == previous[0].seasonmare_status)
					}
				}
			},

			cancelAjout() {
				this.cancel()
				this.shutterPanel().close('monte-horse-ajout')
				this.shutterPanel().close('monte-contact-ajout')
			},

			async checkForm() {
				this.error = []
				if(!this.stallion) {
					this.error.push('stallion')
				}
				if(!this.schedule) {
					this.error.push('schedule')
				}
				if(this.error.length > 0) {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}
				this.processing = true

				const mare = this.mare ? this.mare.horse_id : null;
				const contact = (!this.showTier && this.contact) ? this.contact.contact_id : null;
				const schedulehorse_id = this.schedule_info ? this.schedule_info.id : null
				const tiers = (this.showTier && this.tiers) ? this.tiers.tiers_id : null;

				if(mare && this.mare_status_selected) {
					await this.saveStatutSanitary(this.season_mare.seasonmare_id, this.mare_status_selected.marestatus_id, this.sanitary)
				}
				else if(mare) {
					await this.saveStatutSanitary(this.season_mare.seasonmare_id, null, this.sanitary)
				}
				await this.$sync.force(true, true)

				if(mare) {
					const seasonmarestallion = await this.getSeasonMareStallionDefaultBySeasonMare(this.season_mare.seasonmare_id)
					if(!seasonmarestallion || seasonmarestallion.seasonmarestallion_horse != this.stallion.horse_id) {
						await this.editAllSeasonMareStallionDefault(this.season_mare.seasonmare_id, { seasonmarestallion_default: 0 })
						const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id)
						if(!seasonstallion) {
							await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1)
						} else {
							await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1 })
						}
					}

					if(this.mare.horse_id && this.lieu_stationnement && this.lieu_stationnement.tiers_id) {
            			await this.assignLieuStationnement(this.mare.horse_id, this.lieu_stationnement.tiers_id)
					}
				}

				const res = await this.saveScheduleHorseOnline("IA", this.stallion.horse_id, this.schedule.schedule_id, mare, contact, this.date, schedulehorse_id, this.commentaire, tiers)

				if(res === false) {
					this.processing = false
					this.failureToast("monte.jument_already_schedule")
				}
				else {
					this.processing = false
					this.ok()
					this.shutterPanel().close('monte-ia-ajout-beta')
					this.shutterPanel().close('monte-horse-ajout')
					this.shutterPanel().close('monte-contact-ajout')
					await this.$sync.force(true, true)
				}
			},

			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			},

			async loadContractInfo() {
				this.no_contract = false
				if(!this.mare || !this.mare.horse_id || !this.stallion) return false

				const contrats = await this.loadContracts(this.mare.horse_id)

				const avenant = contrats.filter(avenant => {
					if(avenant.contract.length === 0 || avenant.contract.config.length === 0) return false
					if(!avenant.contract.config.horse) return false
					return avenant.contract.config.horse.horse_id == this.stallion.horse_id
					&& avenant.contract.config.season.season_id == this.season_id
					&& avenant.avenant_id == avenant.contract.avenant.avenant_id
				})

				if(avenant.length == 0) {
					this.no_contract = true
				}
			},

			async checkSeasonMareStallion() {
				this.no_couple = false
				if(!this.season_mare && this.mare && this.mare.horse_id && this.season_id) {
					this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(this.mare.horse_id, this.season_id)
					this.season_mare = this.season_mare[0]
				}
 
				if(this.season_mare && this.stallion && this.stallion.horse_id) {
					this.no_couple = true
					const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id)
					if(seasonstallion) {
						this.no_couple = false
					}
				}
			},

			async checkSaillie() {
				// affiche un message s'il y a une saillie entre 2 jours avant et après le créneau
				this.close_saillie = false
				if(this.mare && this.mare.horse_id) {
					let date_prev = new Date(this.date);
					date_prev.setDate(this.date.getDate() - 2);

					let date_next = new Date(this.date);
					date_next.setDate(this.date.getDate() + 2);

					const saillie = await this.getSaillieByMareBetweenDate(this.mare.horse_id, date_prev, date_next)
					if(saillie.length > 0) {
						this.close_saillie = true
						this.close_saille_message = this.getTrad('monte.close_saillie', {date: saillie[0].actes_date.toLocaleDateString()})
					}
				}
			},

			async loadLieuStationnement() {
				if(this.mare && this.mare.horse_id) {
					this.lieu_stationnement = await this.getHorseLieuStationnement(this.mare.horse_id)
				}
			},

			async loadContact() {
				if(!this.contact && this.mare) {
					const contact_mare = await this.getContactByHorse(this.mare.horse_id)
					if(contact_mare.length > 0) {
						const resp = contact_mare.find(contact => contact.fonctions.find(fct => fct.fonction_code == 'RESPELEV'))
						if(resp) {
							this.contact = resp.contact
						} else {
							this.contact = contact_mare[0].contact
						}
					}
				}
			}
		},

		watch: {
			mare (val) {
				this.selectStatus()
				this.loadContractInfo()
				this.loadContact()
				this.checkSeasonMareStallion()
				this.checkSaillie()
				this.loadLieuStationnement()
			},

			stallion (val) {
				if(val) { 
					this.schedule = null
					this.loadSchedules()
					this.loadContractInfo()
					this.checkSeasonMareStallion()
				}
			},

			date() {
				if(this.stallion) {
					this.loadSchedules()
				}
				this.checkSaillie()
			},

			showTier(val) {
				if(val) {
					this.tiers_search_value = this.contact_search_value
				}
				else {
					this.contact_search_value = this.tiers_search_value
				}
			}
		},

		components: {
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			SearchContact : () => import('@/components/Contract/SearchContact'),
			SearchJument : () => import('@/components/Contract/SearchJument')
		}
	}

</script>